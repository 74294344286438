import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Chance from 'chance';
import ConfettiGenerator from 'confetti-js';

import data from './data.json';

const date = new Date();
const dateCode = String(date.getDate()) + String(date.getFullYear());
const seeds = [
  dateCode + '1',
  dateCode + '2',
  dateCode + '3',
  dateCode + '4',
  dateCode + '5',
  dateCode + '6',
  dateCode + '7',
  dateCode + '8',
  dateCode + '9',
  dateCode + '10'
];

const dates = seeds.map(seed => new Chance(seed));
const chosenIndexes = [];

dates.forEach((date, i) => {
  let chosenIndex = date.integer({ min: 0, max: data.length - 1 });
  let newSeed = seeds[i];
  while (chosenIndexes.includes(chosenIndex)) {
    newSeed = newSeed + '1';
    const newDate = new Chance(newSeed);
    chosenIndex = newDate.integer({ min: 0, max: data.length - 1 });
  }
  chosenIndexes.push(chosenIndex);
});

const chosenData = chosenIndexes.map(index => ({
  ...data[index],
  solved: false,
}));

const chance = new Chance(dateCode);

const SEGMENTS = chance.shuffle(chosenData.reduce((prev, { segments }) => {
  return prev.concat(segments);
}, []));

function hideWord(w) {
  if (w.length < 2) return w;
  return w.substring(0, 1) + '⬜'.repeat(w.length - 1);
}

function hideAnswer(a) {
  return a.split(" ").map(hideWord).join(" ");
}

function formatSeconds(s) {
  return ((s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s);
}

const COLOR_DARK = 'rgb(30, 30, 30)';
const COLOR_X_DARK = 'rgb(24, 24, 24)';
const COLOR_LIGHT = 'rgb(217, 217, 217)';
const COLOR_LIGHT_GREEN = 'rgb(169, 242, 121)';
const COLOR_GREEN = 'rgb(141, 243, 75)';

const URL = 'https://wordbreak.io';

const THREE_STAR_CEILING = 120;
const TWO_STAR_CEILING = 300;

const INITIAL_PROGRESS = [false, false, false, false, false, false, false, false, false, false];

function App() {
  const [guess, setGuess] = useState([]);
  const [guessedIndexes, setGuessedIndexes] = useState([]);
  const [solvedIndexes, setSolvedIndexes] = useState([]);
  const [progress, setProgress] = useState(INITIAL_PROGRESS);
  const [isSolving, setIsSolving] = useState(false);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [showWin, setShowWin] = useState(false);
  const [showTextCopied, setShowTextCopied] = useState(false);

  const isSolved = useMemo(() => progress.every((i) => i), [progress]);

  const handleGuess = (guess, i) => () => {
    if (!guessedIndexes.includes(i) && !solvedIndexes.includes(i) && isSolving) {
      setGuess((prevGuess) => [...prevGuess, guess]);
      setGuessedIndexes((prevGuessedIndexes) => [...prevGuessedIndexes, i]);
    }
  };

  const handleClear = () => {
    if (isSolving) {
      setGuess([]);
      setGuessedIndexes([]);
    }
  };

  const handleStart = () => {
    setIsSolving(true);
  };

  const copyText = () => {
    let text = 'Check out WordBreak!'
    if (timeElapsed <= TWO_STAR_CEILING) {
      const starsText = timeElapsed <= THREE_STAR_CEILING ? '3 stars ⭐⭐⭐ ' : '2 stars ⭐⭐ ';
      text = `I got ${starsText} on WordBreak!`;
    }
    navigator.clipboard.writeText(text);
  };

  const handleShareFacebook = () => {
    setShowTextCopied(true);
    copyText();
    window && window.FB && window.FB.ui({
      href: URL,
      method: 'share'
    }, response => {
      console.log('fb response', response);
    });
  };

  const handleShareTwitter = () => {
    const url = "https://wordbreak.io";
    let text = 'Check out WordBreak!'
    if (timeElapsed <= TWO_STAR_CEILING) {
      const starsText = timeElapsed <= THREE_STAR_CEILING ? '3 stars ⭐⭐⭐ ' : '2 stars ⭐⭐ ';
      text = `I got ${starsText} on WordBreak!`;
    }
    window.open('http://twitter.com/share?url=' + encodeURIComponent(url) + '&text=' + encodeURIComponent(text), '', 'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0');
  };

  const handleShareLinkedin = () => {
    setShowTextCopied(true);
    copyText();
    const url = "https://wordbreak.io";
    window.open('http://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(url), '', 'left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0');
  };

  useEffect(() => {
    const storedTimeElapsed = Number(localStorage.getItem('timeElapsed'));
    const storedDateCode = localStorage.getItem('dateCode');
    if (storedTimeElapsed && storedDateCode === dateCode) {
      const storedSolvedIndexes = JSON.parse(localStorage.getItem('solvedIndexes')) || [];
      const storedProgress = JSON.parse(localStorage.getItem('progress')) || INITIAL_PROGRESS;
      setSolvedIndexes(storedSolvedIndexes);
      setProgress(storedProgress);
      setTimeElapsed(storedTimeElapsed);
      setIsSolving(true);
    }
  }, []);

  useEffect(() => {
    if (isSolved) {
      setIsSolving(false);
      const confettiSettings = {
        max: 80,
        target: 'my-canvas',
      };
      const confetti = new ConfettiGenerator(confettiSettings);
      confetti.render();
      setShowWin(true);

      return () => {
        confetti.clear();
      };
    }
  }, [isSolved]);

  useEffect(() => {
    if (isSolving) {
      const interval = setInterval(() => {
        setTimeElapsed((prevTimeElapsed) => {
          const newTimeElapsed = prevTimeElapsed + 1;
          localStorage.setItem('timeElapsed', newTimeElapsed);
          return newTimeElapsed;
        });
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [isSolving]);

  useEffect(() => {
    if (isSolving) {
      localStorage.setItem('dateCode', dateCode);
    }
  }, [isSolving]);

  useEffect(() => {
    if (guess.length > 0) {
      const foundIndex = chosenData.findIndex(({ answer }) => answer.replace(/\s/g, '') === guess.join(''));
      if (foundIndex !== -1) {
        setProgress((prevProgress) => {
          const newProgress = [...prevProgress];
          newProgress[foundIndex] = true;
          localStorage.setItem('progress', JSON.stringify(newProgress));
          return newProgress;
        });
        setSolvedIndexes((prevSolvedIndexes) => {
          const newSolvedIndexes = [...prevSolvedIndexes, ...guessedIndexes];
          localStorage.setItem('solvedIndexes', JSON.stringify(newSolvedIndexes));
          return newSolvedIndexes;
        });
        setGuess([]);
        setGuessedIndexes([]);
      }
    }
  }, [guess, guessedIndexes]);

  const showStart = !isSolving && !isSolved;

  return (
    <Container>
      <SectionOne>
        {showStart ? (
          <StartContainer>
            <TitleContainer>
              <TitleOneText>
                Word
              </TitleOneText>
              <TitleTwoText>
                Break
              </TitleTwoText>
            </TitleContainer>
            <SubTitleContainer>
              New puzzle daily!
            </SubTitleContainer>
            <StartButton
              onClick={handleStart}
            >
              Start Playing
            </StartButton>
          </StartContainer>
        ) : (
          isSolved ? null : (
            <CluesContainer>
              {chosenData.map(({ answer, clue }, i) => (
                <ClueContainer
                  key={clue}
                >
                  <Answer
                    solved={progress[i]}
                  >
                    {progress[i] ? answer : hideAnswer(answer)}
                  </Answer>
                  <Clue>
                    {clue}
                  </Clue>
                </ClueContainer>
              ))}
            </CluesContainer>
          )
        )}
      </SectionOne>
      {showStart ? null : (
        isSolved ? null : (
          <SectionTwo>
            <TitleContainer>
              <TitleOneText>
                Word
              </TitleOneText>
              <TitleTwoText>
                Break
              </TitleTwoText>
            </TitleContainer>
            <TimeContainer>
              Time: {formatSeconds(timeElapsed)}
            </TimeContainer>
            <GuessContainer>
              <GuessBox>
                {guess.map((segment) => (
                  <GuessSegment>
                    {segment}
                  </GuessSegment>
                ))}
              </GuessBox>
              <ClearButton
                disabled={!isSolving}
                onClick={handleClear}
              >
                X
              </ClearButton>
            </GuessContainer>
            <HowToContainer>
              Combine the word fragments to find a word that solves a clue.
            </HowToContainer>
            <SegmentsContainer>
              {SEGMENTS.map((segment, i) => (
                (solvedIndexes.includes(i) || !isSolving) ? (
                  <SolvedContainer />
                ) : (
                  <SegmentContainer
                    disabled={guessedIndexes.includes(i)}
                    key={segment + i}
                    onClick={handleGuess(segment, i)}
                  >
                    {segment}
                  </SegmentContainer>
                )
              ))}
            </SegmentsContainer>
          </SectionTwo>
        )
      )}
      {showWin ? (
        <WinContainer>
          <TitleContainer>
            <TitleOneText>
              Word
            </TitleOneText>
            <TitleTwoText>
              Break
            </TitleTwoText>
          </TitleContainer>
          <TimeSolvedContainer>
            You solved it in {formatSeconds(timeElapsed)}!
          </TimeSolvedContainer>
          <StarContainer>
            <Star
              src="/star.png"
            />
            {timeElapsed <= TWO_STAR_CEILING ? (
              <Star
                spacer
                src="/star.png"
              />
            ) : (
              <Star
                spacer
                src="/star-blank.png"
              />
            )}
            {timeElapsed <= THREE_STAR_CEILING ? (
              <Star
                spacer
                src="/star.png"
              />
            ) : (
              <Star
                spacer
                src="/star-blank.png"
              />
            )}
          </StarContainer>
          <ShareTextContainer>
            Share:
          </ShareTextContainer>
          <ShareIconsContainer>
            <ShareIconContainer
              onClick={handleShareFacebook}
            >
              <ShareIcon
                src="/share-facebook.png"
              />
            </ShareIconContainer>
            <ShareIconContainer
              onClick={handleShareTwitter}
              spacer
            >
              <ShareIcon
                src="/share-twitter.png"
              />
            </ShareIconContainer>
            <ShareIconContainer
              onClick={handleShareLinkedin}
              spacer
            >
              <ShareIcon
                src="/share-linkedin.png"
              />
            </ShareIconContainer>
          </ShareIconsContainer>
        </WinContainer>
      ) : null}
      <CanvasContainer
        id="my-canvas"
      />
      {showTextCopied ? (
        <CopyTextContainer>
          Text copied to clipboard!
        </CopyTextContainer>
      ) : null}
      <ContactContainer
        href="mailto:llviaquez@icloud.com"
      >
        Contact
      </ContactContainer>
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  background-color: ${COLOR_DARK};
  color: ${COLOR_LIGHT};
  display: flex;
  flex-basis: 1;
  font-size: 20px;
  justify-content: center;
  min-height: 100vh;
  position: relative;
  width: 100%;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const SectionOne = styled.div`
  display: flex;
  margin-right: 20px;
  padding: 10px;
  width: 440px;

  @media screen and (max-width: 900px) {
    margin-bottom: 40px;
    order: 2;
  }

  @media screen and (max-width: 440px) {
    width: 340px;
  }
`;

const SectionTwo = styled.div`
  display: flex;
  flex-direction: column;
  width: 440px;

  @media screen and (max-width: 900px) {
    margin-bottom: 30px;
    margin-top: 40px;
    order: 1;
  }

  @media screen and (max-width: 440px) {
    width: 340px;
  }
`;

const CluesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StartContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 900px) {
    margin-top: -400px;
  }
`;

const StartButton = styled.div`
  align-items: center;
  background-color: ${COLOR_GREEN};
  border-radius: 5px;
  color: ${COLOR_DARK};
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 50px;
  margin-top: 20px;
  text-align: center;
  width: 200px;

  &:hover {
    background-color: ${COLOR_LIGHT_GREEN};
  }

  &:active {
    transform: translateY(2px);
  }
`;

const ClueContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 10px;
`;

const Answer = styled.div`
  color: ${({ solved }) => solved ? COLOR_GREEN : COLOR_LIGHT};
  height: 30px;
  letter-spacing: 4px;
`;

const Clue = styled.div`
  font-size: 16px;
`;

const SegmentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SegmentContainer = styled.div`
  align-items: center;
  background-color: ${({ disabled }) => disabled ? COLOR_DARK : COLOR_LIGHT};
  border: 1px solid ${COLOR_LIGHT};
  border-radius: 5px;
  color: ${({ disabled }) => disabled ? COLOR_LIGHT : COLOR_DARK};
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  display: flex;
  height: 35px;
  justify-content: center;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px 0;
  width: 80px;

  &:hover {
    background-color: ${COLOR_DARK};
    color: ${COLOR_LIGHT};
  }

  &:active {
    transform: translateY(2px);
  }
`;

const SolvedContainer = styled.div`
  height: 35px;
  width: 80px;
  margin-bottom: 5px;
  margin-right: 5px;
`;

const GuessContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const GuessBox = styled.div`
  align-items: center;
  background-color: ${COLOR_DARK};
  border: 1px solid ${COLOR_LIGHT};
  border-radius: 5px;
  color: ${COLOR_LIGHT};
  display: flex;
  font-size: 24px;
  height: 40px;
  justify-content: center;
  margin-right: 10px;
  overflow-x: hidden;
  padding: 10px 0;
  width: 370px;
`;

const ClearButton = styled.div`
  align-items: center;
  background-color: ${COLOR_DARK};
  border: 1px solid ${COLOR_LIGHT};
  border-radius: 5px;
  color: ${COLOR_LIGHT};
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;

  &:hover {
    background-color: ${({ disabled }) => disabled ? COLOR_DARK : COLOR_LIGHT};
    color: ${({ disabled }) => disabled ? COLOR_LIGHT : COLOR_DARK};
  }

  &:active {
    transform: translateY(2px);
  }
`;

const TitleContainer = styled.div`
  font-size: 40px;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
`;

const TitleOneText = styled.span`
  color: ${COLOR_LIGHT};
`;

const TitleTwoText = styled.span`
  color: ${COLOR_GREEN};
`;

const TimeContainer = styled.div`
  display: flex;
  font-size: 24px;
  justify-content: center;
  margin-bottom: 20px;
`;

const WinContainer = styled.div`
  align-items: center;
  background-color: ${COLOR_X_DARK};
  border-radius: 5px;
  border: 1px solid ${COLOR_DARK};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  position: absolute;
  width: 600px;
  z-index: 100;

  @media screen and (max-width: 900px) {
    margin-top: -300px;
    width: 440px;
  }

  @media screen and (max-width: 440px) {
    width: 350px;
  }
`;

const StarContainer = styled.div`
  display: flex;
`;

const Star = styled.img`
  margin-left: ${({ spacer }) => spacer ? '20px' : '0px'};
  width: 100px;
`;

const TimeSolvedContainer = styled.div`
  font-size: 30px;
  margin-bottom: 30px;
  margin-top: 20px;
`;

const ShareTextContainer = styled.div`
  font-size: 30px;
  margin-top: 30px;
`;

const ShareIconsContainer = styled.div`
  display: flex;
  font-size: 30px;
  margin-top: 30px;
`;

const ShareIcon = styled.img`
  width: 100%;
`;

const ShareIconContainer = styled.div`
  cursor: pointer;
  margin-left: ${({ spacer }) => spacer ? '20px' : '0px'};
  width: 45px;
`;

const CanvasContainer = styled.canvas`
  position: absolute;
  top: 0;
  z-index: 10;
`;

const CopyTextContainer = styled.div`
  align-items: center;
  background-color: ${COLOR_GREEN};
  border: 1px solid ${COLOR_LIGHT};
  border-radius: 5px;
  bottom: 100px;
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.01);
  color: ${COLOR_DARK};
  display: flex;
  font-size: 16px;
  height: 50px;
  justify-content: center;
  opacity: .9;
  position: absolute;
  width: 300px;
  z-index: 100;
`;

const GuessSegment = styled.div`
  align-items: center;
  background-color: ${COLOR_LIGHT};
  border-radius: 5px;
  color: ${COLOR_DARK};
  display: flex;
  justify-content: center;
  margin-right: 5px;
  padding: 5px 0;
  height: 30px;
  width: 80px;
`;

const SubTitleContainer = styled.div`
  font-size: 20px;
`;

const HowToContainer = styled.div`
  font-size: 14px;
  margin-bottom: 20px;
`;

const ContactContainer = styled.a`
  color: ${COLOR_LIGHT};
  font-size: 20px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  text-decoration: none;
`;

export default App;
